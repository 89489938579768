import PieChart from '../../helpers/chart.js'
import { mapActions, mapGetters } from 'vuex'
import packageColors from '../../helpers/package-colors.js'
import ReportExport from '../../features/report-export/index.vue'
import reportMixin from '@/mixins/reportMixin'

export default {
  name: 'ModuleStatistics',
  components: {
    PieChart,
    ReportExport
  },
  mixins: [reportMixin],
  computed: {
    ...mapGetters({
      courseDetalies: 'statistics/courseDetaliesData'
    }),
    courseReportFn() {
      return this.createReportFn(
        {
          url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}`,
          method: 'get'
        }
      )
    },
    userWorksReportFn() {
      return this.createReportFn(
        {
          url: `${process.env.VUE_APP_ROOT_STATISTIC}courses/${this.$route.params.courseId}/users/works`,
          method: 'get'
        }
      )
    },
    headersMaterials() {
      return [
        {
          text: 'Title',
          value: 'name'
        },
        {
          text: 'Students',
          value: 'students'
        },
        {
          text: 'Progress (%)',
          value: 'progress'
        },
        {
          text: 'AVG points',
          value: 'points'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    headersModule() {
      return [
        {
          text: 'Title',
          value: 'name'
        },
        {
          text: 'Students',
          value: 'students'
        },
        {
          text: 'Progress (%)',
          value: 'progress'
        },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions'
        }
      ]
    },
    packageLabels() {
      return this.getOnlyValues(this.courseDetalies.packages.data, 'name')
    },
    packageValues() {
      return this.getOnlyValues(this.courseDetalies.packages.data, 'count')
    },
    colors() {
      return this.getOnlyValues(packageColors.colors, 'color')
    },
    chartData: {
      get() {
        return {
          hoverBackgroundColor: 'red',
          hoverBorderWidth: 10,
          labels: this.packageLabels,
          datasets: [
            {
              backgroundColor: this.colors,
              data: this.packageValues
            }
          ]
        }
      },
      set(value) {
        return {
          ...value
        }
      }
    }
  },
  created() {
    if (this.$route.params.hasOwnProperty('courseId')) {
      this.fetchCourse({ courseId: this.$route.params.courseId })
    }
  },
  data() {
    return {
      interval: {},
      chartOptions: {
        hoverBorderWidth: 20
      }
    }
  },
  mounted() {
    this.generateData()
  },
  methods: {
    ...mapActions({
      fetchCourse: 'statistics/GET_STATISTICS_COURSE_DETALIES'
    }),
    getOnlyValues(array, fieldName) {
      return array.map(item => item[fieldName])
    },
    generateData() {
      this.chartData += this.chartData
    }
  }
}
